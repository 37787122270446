import React, { Component } from "react";
import Zmage from "react-zmage";
import Fade from "react-reveal";

const iframeStyle = {
  
  width: '100%',
  height: '500px',
  frameBorder: '0',
  
};
let id = 0;
class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map(function (projects) {
      let projectImage = "images/portfolio/" + projects.image;

      return (
        <div key={id++} className="columns portfolio-item">
          <div className="item-wrap">
            <Zmage alt={projects.title} src={projectImage} />
            <div style={{ textAlign: "center" }}>{projects.title}</div>
          </div>
        </div>
      );
    });

    return (
      <section id="portfolio">
        <Fade left duration={1000} distance="40px">
          <div className="row">
            <div className="twelve columns collapsed">
              <h1>Check Out Some of My Works.</h1>

              <div
                id="portfolio-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >
                {projects}
              </div>
              <iframe 
                src="https://open.spotify.com/embed/playlist/7vSYF7V3HMm9GxtBjbiLlx?utm_source=generator&theme=0" 
                title="Spotify track - Rick Astley - Never Gonna Give You Up" 
                style={iframeStyle} 
                allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"
                
            />
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Portfolio;
