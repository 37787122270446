import React, { Component } from "react";
//import ParticlesBg from "particles-bg";
import Fade from "react-reveal";
import { useState } from "react";
function Header (props){
  const [showQuoteForm, setShowQuoteForm] = useState(false);

  const toggleQuoteForm = () => {
    setShowQuoteForm(!showQuoteForm);
  };

    if (!props.data) return null;

    const project = props.data.project;
    // const github = props.data.github;
    // const name = props.data.name;
    const description = props.data.description;

    return (
      <header id="home">
        {/* <ParticlesBg type="circle" bg={true} /> */}
        <video poster= "/images/cover-photo.jpeg" width="320" height="240" autoPlay loop muted>
        <source src="/video/sensational.mp4"  loop autoPlay type="video/mp4"/>
      
        Your browser does not support the video tag.
        </video>
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#portfolio">
                Works
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              {/* <h1 className="responsive-headline">{name}</h1> */}
              <img src="/images/logo.png" alt="description of the image"/>
            </Fade>
            <Fade bottom duration={1200}>
              <h3>{description}.</h3>
            </Fade>
            <hr />
            <Fade bottom duration={2000}>
              <ul className="social">
                <a href={project} target="_blank" className="button btn project-btn" onClick={toggleQuoteForm}>
                  <i className="fa fa-book"></i>Get a Quote
                </a>
                <a href="https://vibewithxq.beatstars.com/" className="button btn github-btn">
                 Buy Beats
                </a>
              </ul>
            </Fade>
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
       
      </header>
    );
}

export default Header;
